import React from 'react';

const VoiceIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10.5H3.3V18.5H1V10.5Z" fill="#2E7FF9"/>
            <path d="M5.7 6.5H8V22.5H5.7V6.5Z" fill="#2E7FF9"/>
            <path d="M10.4 11.5H12.7V17.5H10.4V11.5Z" fill="#2E7FF9"/>
            <path d="M15.1 4H17.3V25H15.1V4Z" fill="#2E7FF9"/>
            <path d="M19.7 8.5H22V20.5H19.7V8.5Z" fill="#2E7FF9"/>
            <path d="M24.4 11.5H26.7V17.5H24.4V11.5Z" fill="#2E7FF9"/>
        </svg>
    );
}

export default VoiceIcon;
