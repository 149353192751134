import React, {useCallback, useEffect, useRef, useState} from 'react';
import {IMediaRecorder, MediaRecorder} from 'extendable-media-recorder';
import {State} from "../../enums";
import {usePlay} from "../../contexts/PlayContext/PlayContext";
import {useNotifications} from "../../contexts/NotificationContext/NotificationContext";
import PlayIcon from "../Icon/PlayIcon";
import MicrophoneIcon from "../Icon/MicrophoneIcon";
import './VoiceInput.css';

let interval: NodeJS.Timer;

interface VoiceButtonProps {
    state: State;
    setState: (state: State) => void;
    message: string;
    setMessage: (message: string) => void;
    onQuestion: (record: string | Blob) => Promise<void>;
    onPlay: () => void;
}
const VoiceInput = ({ state, setState, message, setMessage, onQuestion, onPlay }: VoiceButtonProps) => {
    const voiceInputRef = useRef<HTMLDivElement | null>(null);

    const { canPlay, playStart, playStop } = usePlay();
    const { setNotification } = useNotifications();

    const [recorder, setRecorder] = useState<IMediaRecorder>();

    const [startRecordTime, setStartRecordTime] = useState<number>(0);
    const [timerKey, setTimerKey] = useState<number>(0);

    const formatTime = (start: number) => {
        if (start === 0) {
            return '00:00,00';
        }

        const time = Date.now() - start;
        const obj = new Date(time);

        const minutes = obj.getMinutes();
        const seconds = obj.getSeconds();
        const ms = Math.round((time - ((minutes * 60 + seconds) * 1000)) / 10);

        return `${minutes >= 10 ? minutes : '0' + minutes}:${seconds >= 10 ? seconds : '0' + seconds},${ms >= 10 ? ms : '0' + ms}`;
    };

    const startRecord = useCallback(async (event: any) => {
        if (canPlay) {
            return;
        }

        event.preventDefault();

        if (state !== State.READY) {
            return;
        }

        setState(State.LISTEN);
        setMessage('Удерживайте и говорите');

        setStartRecordTime(0);
        playStart().catch(e => console.log(e));

        navigator.mediaDevices.getUserMedia({
            audio: {
                echoCancellation: true,
            }
        }).then((mediaStream) => {
            const startRecordTime = Date.now();
            const mediaRecorder = new MediaRecorder(mediaStream, { mimeType: 'audio/wav' });

            mediaRecorder.addEventListener('dataavailable', event => {
                setRecorder(undefined);

                if (startRecordTime === 0 || (new Date(Date.now() - startRecordTime)).getSeconds() < 2) {
                    setState(State.READY);
                    setMessage('');

                    setNotification('Слишком короткая запись');
                    return;
                }

                onQuestion(new Blob([event.data], { type: mediaRecorder.mimeType }));
            });
            mediaRecorder.addEventListener('stop', async () => {
                mediaStream.getTracks().forEach(track => track.stop());
            });

            mediaRecorder.start();
            setRecorder(mediaRecorder);

            setStartRecordTime(Date.now());
            interval = setInterval(() => setTimerKey(prev => prev ? 0 : 1), 10);
        }).catch((e) => {
            setState(State.READY);
            setMessage('');

            setNotification(e.toString());
        });
    }, [canPlay, state, playStart, setMessage, setState, setNotification, onQuestion]);
    const stopRecord = useCallback((event: any) => {
        if (canPlay) {
            return;
        }

        event.preventDefault();

        if (state !== State.LISTEN) {
            setState(State.READY);
            setMessage('');

            return;
        }

        if (!recorder) {
            setState(State.WAITING);
            setMessage('');

            return;
        }

        playStop().catch(e => console.log(e));
        clearInterval(interval);

        recorder.stop();
    }, [canPlay, state, setState, setMessage, playStop, recorder]);

    useEffect(() => {
        if (!recorder || state === State.LISTEN) {
            return;
        }

        playStop().catch(e => console.log(e));

        clearInterval(interval);
        recorder.stop();
    }, [recorder, state, playStop]);
    useEffect(() => {
        if (!voiceInputRef.current) {
            return;
        }

        const voiceInput = voiceInputRef.current;

        voiceInput.addEventListener('touchstart', startRecord, { passive: false });
        voiceInput.addEventListener('touchend', stopRecord, { passive: false });

        return () => {
            if (!voiceInput) {
                return;
            }

            voiceInput.removeEventListener('touchstart', startRecord);
            voiceInput.removeEventListener('touchend', stopRecord);
        };
    }, [startRecord, stopRecord]);

    return (
        <div
            ref={voiceInputRef}
            className="voice-input"
            onMouseDown={startRecord}
            onMouseUp={stopRecord}
            onClick={canPlay ? onPlay : undefined}
        >
            <p>{message}</p>
            <div className={State[state].toLowerCase()}>
                <div style={{display: canPlay ? 'flex' : 'none'}}>
                    <PlayIcon/>
                </div>
                <div style={{display: !canPlay && state !== State.LISTEN ? 'flex' : 'none'}}>
                    <MicrophoneIcon/>
                </div>
                <div style={{display: !canPlay && state === State.LISTEN ? 'flex' : 'none'}}>
                    <div></div>
                    <span key={timerKey}>{formatTime(startRecordTime)}</span>
                </div>
            </div>
        </div>
    );
};

export default VoiceInput;
