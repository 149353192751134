import React from 'react';

const KeyboardIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.5 4.0835H3.50002C2.21669 4.0835 1.16669 5.1335 1.16669 6.41683V21.5835C1.16669 22.8668 2.21669 23.9168 3.50002 23.9168H24.5C25.7834 23.9168 26.8334 22.8668 26.8334 21.5835V6.41683C26.8334 5.1335 25.7834 4.0835 24.5 4.0835ZM24.5 21.5835H3.50002V6.41683H24.5V21.5835ZM10.5 8.75016H12.8334V11.0835H10.5V8.75016ZM5.83335 8.75016H8.16669V11.0835H5.83335V8.75016ZM9.33335 18.0835H18.6667V19.2502H9.33335V18.0835ZM15.1667 8.75016H17.5V11.0835H15.1667V8.75016ZM10.5 13.4168H12.8334V15.7502H10.5V13.4168ZM5.83335 13.4168H8.16669V15.7502H5.83335V13.4168ZM15.1667 13.4168H17.5V15.7502H15.1667V13.4168ZM19.8334 8.75016H22.1667V11.0835H19.8334V8.75016ZM19.8334 13.4168H22.1667V15.7502H19.8334V13.4168Z"
                fill="#2E7FF9"/>
        </svg>
    );
}

export default KeyboardIcon;
