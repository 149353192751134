import React from 'react';

const MicrophoneIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_30_555)">
                <path
                    d="M13.9998 23.3333C16.4743 23.3306 18.8467 22.3463 20.5964 20.5966C22.3462 18.8469 23.3304 16.4745 23.3332 14V9.33333C23.3332 6.85798 22.3498 4.48401 20.5995 2.73367C18.8492 0.983331 16.4752 0 13.9998 0C11.5245 0 9.15051 0.983331 7.40017 2.73367C5.64983 4.48401 4.6665 6.85798 4.6665 9.33333V14C4.66928 16.4745 5.65351 18.8469 7.40324 20.5966C9.15298 22.3463 11.5253 23.3306 13.9998 23.3333ZM13.9998 2.33333C15.6525 2.3364 17.2509 2.92351 18.5125 3.99093C19.7742 5.05835 20.618 6.53737 20.8948 8.16667H17.4998C17.1904 8.16667 16.8937 8.28958 16.6749 8.50838C16.4561 8.72717 16.3332 9.02391 16.3332 9.33333C16.3332 9.64275 16.4561 9.9395 16.6749 10.1583C16.8937 10.3771 17.1904 10.5 17.4998 10.5H20.9998V12.8333H17.4998C17.1904 12.8333 16.8937 12.9563 16.6749 13.175C16.4561 13.3938 16.3332 13.6906 16.3332 14C16.3332 14.3094 16.4561 14.6062 16.6749 14.825C16.8937 15.0438 17.1904 15.1667 17.4998 15.1667H20.8948C20.6206 16.7971 19.7775 18.2776 18.5153 19.3453C17.253 20.413 15.6531 20.9989 13.9998 20.9989C12.3465 20.9989 10.7467 20.413 9.48443 19.3453C8.22213 18.2776 7.37904 16.7971 7.10484 15.1667H10.4998C10.8093 15.1667 11.106 15.0438 11.3248 14.825C11.5436 14.6062 11.6665 14.3094 11.6665 14C11.6665 13.6906 11.5436 13.3938 11.3248 13.175C11.106 12.9563 10.8093 12.8333 10.4998 12.8333H6.99984V10.5H10.4998C10.8093 10.5 11.106 10.3771 11.3248 10.1583C11.5436 9.9395 11.6665 9.64275 11.6665 9.33333C11.6665 9.02391 11.5436 8.72717 11.3248 8.50838C11.106 8.28958 10.8093 8.16667 10.4998 8.16667H7.10484C7.38165 6.53737 8.22545 5.05835 9.48714 3.99093C10.7488 2.92351 12.3472 2.3364 13.9998 2.33333Z"
                    fill="#4A92FF"/>
                <path
                    d="M26.8333 14C26.5239 14 26.2272 14.1229 26.0084 14.3417C25.7896 14.5605 25.6667 14.8572 25.6667 15.1667C25.6636 17.9505 24.5563 20.6194 22.5879 22.5879C20.6194 24.5563 17.9505 25.6636 15.1667 25.6667H12.8333C10.0496 25.6633 7.38086 24.5559 5.41246 22.5875C3.44407 20.6191 2.33673 17.9504 2.33333 15.1667C2.33333 14.8572 2.21042 14.5605 1.99162 14.3417C1.77283 14.1229 1.47609 14 1.16667 14C0.857247 14 0.560501 14.1229 0.341709 14.3417C0.122916 14.5605 0 14.8572 0 15.1667C0.00401388 18.569 1.35738 21.8309 3.76323 24.2368C6.16908 26.6426 9.43095 27.996 12.8333 28H15.1667C18.569 27.996 21.8309 26.6426 24.2368 24.2368C26.6426 21.8309 27.996 18.569 28 15.1667C28 14.8572 27.8771 14.5605 27.6583 14.3417C27.4395 14.1229 27.1428 14 26.8333 14Z"
                    fill="#4A92FF"/>
            </g>
            <defs>
                <clipPath id="clip0_30_555">
                    <rect width="28" height="28" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default MicrophoneIcon;
