import React from 'react';

const ArrowIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.4925 12.5532C20.3058 12.7399 20.0725 12.8332 19.8392 12.8332C19.6058 12.8332 19.3725 12.7399 19.1858 12.5532L14.9392 8.30653V21.9332C14.9392 22.4465 14.5192 22.8665 14.0058 22.8665C13.4925 22.8665 13.0725 22.4465 13.0725 21.9332V8.30653L8.82582 12.5299C8.45248 12.9032 7.86915 12.9032 7.49581 12.5299C7.12248 12.1565 7.12248 11.5732 7.49581 11.1999L13.3291 5.38986C13.7025 5.01653 14.2858 5.01653 14.6358 5.38986L20.4692 11.1999C20.8658 11.5965 20.8658 12.1799 20.4925 12.5532Z"
                fill="#4A92FF"/>
        </svg>
    );
}

export default ArrowIcon;
