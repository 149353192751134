import React from 'react';

const AvatarIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.5008 15.7498C13.0791 15.7498 15.1675 13.6615 15.1675 11.0832C15.1675 8.50484 13.0791 6.4165 10.5008 6.4165C7.92248 6.4165 5.83415 8.50484 5.83415 11.0832C5.83415 13.6615 7.92248 15.7498 10.5008 15.7498ZM10.5008 8.74984C11.7841 8.74984 12.8341 9.79984 12.8341 11.0832C12.8341 12.3665 11.7841 13.4165 10.5008 13.4165C9.21748 13.4165 8.16748 12.3665 8.16748 11.0832C8.16748 9.79984 9.21748 8.74984 10.5008 8.74984ZM10.5008 18.0832C7.38581 18.0832 1.16748 19.6465 1.16748 22.7498V25.0832H19.8341V22.7498C19.8341 19.6465 13.6158 18.0832 10.5008 18.0832ZM3.50081 22.7498C3.75748 21.9098 7.36248 20.4165 10.5008 20.4165C13.6508 20.4165 17.2675 21.9215 17.5008 22.7498H3.50081ZM17.5941 8.80817C18.5741 10.1848 18.5741 11.9698 17.5941 13.3465L19.5541 15.3182C21.9108 12.9615 21.9108 9.40317 19.5541 6.8365L17.5941 8.80817ZM23.4158 2.9165L21.5141 4.81817C24.7458 8.3415 24.7458 13.6382 21.5141 17.3482L23.4158 19.2498C27.9658 14.7115 27.9775 7.6415 23.4158 2.9165Z"
                fill="#2E7FF9"/>
        </svg>
    );
}

export default AvatarIcon;
