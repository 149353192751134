import React from 'react';
import ReactDOM from 'react-dom/client';
import { register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { PlayProvider } from "./contexts/PlayContext/PlayContext";
import { NotificationProvider } from "./contexts/NotificationContext/NotificationContext";
import App from './App';
import 'normalize.css';

// Register wav encoder;
connect().then(result => register(result));

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <NotificationProvider>
        <PlayProvider>
            <App />
        </PlayProvider>
    </NotificationProvider>
);