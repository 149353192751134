import React from 'react';

const CloseIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.7694 18.2702C20.1894 18.6902 20.1894 19.3435 19.7694 19.7635C19.5594 19.9735 19.3027 20.0669 19.0227 20.0669C18.7427 20.0669 18.4861 19.9735 18.2761 19.7635L14.0061 15.4935L9.73606 19.7635C9.52606 19.9735 9.2694 20.0669 8.9894 20.0669C8.7094 20.0669 8.45275 19.9735 8.24275 19.7635C7.82275 19.3435 7.82275 18.6902 8.24275 18.2702L12.5127 14.0002L8.24275 9.73021C7.82275 9.31021 7.82275 8.65687 8.24275 8.23687C8.66275 7.81687 9.31606 7.81687 9.73606 8.23687L14.0061 12.5069L18.2761 8.23687C18.6961 7.81687 19.3494 7.81687 19.7694 8.23687C20.1894 8.65687 20.1894 9.31021 19.7694 9.73021L15.4994 14.0002L19.7694 18.2702Z"
                fill="#5A9CFF"/>
        </svg>
    );
}

export default CloseIcon;
