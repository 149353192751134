import React, {useState} from 'react';
import {Input, Output, State} from "../../enums";
import TextInput from "../../components/TextInput/TextInput";
import VoiceInput from "../../components/VoiceInput/VoiceInput";
import OutputSwitch from "../../components/OutputSwitch/OutputSwitch";
import InputSwitch from "../../components/InputSwitch/InputSwitch";
import './Control.css';
import {usePlay} from "../../contexts/PlayContext/PlayContext";

interface ControlProps {
    state: State;
    setState: (state: State) => void;
    message: string;
    setMessage: (message: string) => void;
    input: Input;
    setInput: (input: Input) => void;
    output: Output;
    setOutput: (output: Output) => void;
    onQuestion: (question: string | Blob) => Promise<void>;
}
const Control = ({ state, setState, message, setMessage, input, setInput, output, setOutput, onQuestion }: ControlProps) => {
    const { play } = usePlay();
    const [text, setText] = useState<string>('');

    const onSend = () => {
        onQuestion(text);
        setText('');
    };

    const onPlay = () => {
        setState(State.SPEAKING);
        const audioElement = play();

        audioElement.addEventListener('ended', () => {
            setState(State.READY);
        });
    };

    return (
        <div className="control">
            <div className={`control-container ${Input[input].toLowerCase()}`}>
                <OutputSwitch
                    value={output}
                    onChange={setOutput}
                />
                {input === Input.TEXT && (
                    <p>{message}</p>
                )}
                {input === Input.VOICE && (
                    <VoiceInput
                        state={state}
                        setState={setState}
                        message={message}
                        setMessage={setMessage}
                        onQuestion={onQuestion}
                        onPlay={onPlay}
                    />
                )}
                <InputSwitch
                    value={input}
                    onChange={setInput}
                    canSend={!!text}
                    onSend={onSend}
                    onPlay={onPlay}
                />
            </div>
            {input === Input.TEXT && (
                <TextInput
                    state={state}
                    text={text}
                    setText={setText}
                    onSend={onSend}
                />
            )}
        </div>
    );
}

export default Control;
