import React from 'react';
import { State } from "../../enums";
import './VoiceOutput.css';

interface VoiceOutputProps {
    state: State;
}
const VoiceOutput = ({ state }: VoiceOutputProps) => {
    return (
        <div className={`voice-output ${State[state].toLowerCase()}`}>
            <div className="outer">
                <div className="inner"></div>
            </div>
        </div>
    );
}

export default VoiceOutput;
