import React from 'react';

const PlayIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM10.5 16.3301L16.5 12.866C17.1667 12.4811 17.1667 11.5189 16.5 11.134L10.5 7.66987C9.83333 7.28497 9 7.7661 9 8.5359V15.4641C9 16.2339 9.83333 16.715 10.5 16.3301Z"
                  fill="#71A1EA"/>
        </svg>
    );
}

export default PlayIcon;
