export enum State {
    WAITING,
    READY,
    LISTEN,
    PROCESSING,
    SPEAKING,
}

export enum MessageSender {
    USER,
    BOT
}

export enum Input {
    TEXT,
    VOICE
}

export enum Output {
    TEXT,
    VOICE,
    AVATAR
}