import React from 'react';

const PauseIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
                fill="#71A1EA"/>
            <path
                d="M8 8C8 7.44771 8.44772 7 9 7C9.55228 7 10 7.44772 10 8V17C10 17.5523 9.55228 18 9 18C8.44772 18 8 17.5523 8 17V8Z"
                fill="white"/>
            <path
                d="M14 8C14 7.44771 14.4477 7 15 7C15.5523 7 16 7.44772 16 8V17C16 17.5523 15.5523 18 15 18C14.4477 18 14 17.5523 14 17V8Z"
                fill="white"/>
        </svg>
    );
}

export default PauseIcon;
