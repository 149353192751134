import React, { useEffect, useState } from 'react';
import { Input, MessageSender, Output, State } from "./enums";
import { Message } from "./interfaces";
import { usePlay } from "./contexts/PlayContext/PlayContext";
import { useNotifications } from "./contexts/NotificationContext/NotificationContext";
import View from "./containers/View/View";
import Control from "./containers/Control/Control";
import { TextToSpeechApi } from "./api";
import './App.css';


const App = () => {
    const { setAudio } = usePlay();
    const { setNotification } = useNotifications();

    const [speechModels, setSpeechModels] = useState<string[]>([]);

    const [input, setInput] = useState<Input>(Input.VOICE);
    const [output, setOutput] = useState<Output>(Output.VOICE);

    const [state, setState] = useState<State>(State.WAITING);
    const [message, setMessage] = useState<string>('');

    const [botId, setBotId] = useState<string | null>(null);
    const [messages, setMessages] = useState<Message[]>([]);

    const outputSpeech = async (model: string, text: string) => {
        const blob = await TextToSpeechApi.convert(model, text).catch(() => {
            return undefined;
        });

        if (blob === undefined) {
            setState(State.READY);
            setMessage('');

            setNotification('Ошибка преобразования текста в голос');
            return;
        }

        const audio = document.createElement('audio');
        const audioSource = window.URL.createObjectURL(blob);

        audio.src = audioSource;
        audio.onended = () => {
            setState(State.READY);
        };

        await audio.play().then(() => {
            setState(State.SPEAKING);
            setMessage('');
        }).catch(() => {
            setState(State.READY);
            setMessage('');

            setAudio(audioSource);
            setNotification('Нажмите на кнопку записи для прослушивания ответа');
        });
    };
    const outputText = async (message: Message) => {
        setMessage('');
        setMessages(prev => {
            const nextMessages: Message[] = Object.assign([], prev);
            nextMessages.push(message);
            return nextMessages;
        });

        setTimeout(() => {
            const chat = document.getElementById('text-output');

            if (!chat) {
                return;
            }

            chat.scrollTo(0, chat.scrollHeight);
            setState(State.READY);
        }, 200);
    };

    const onQuestion = async (data: string | Blob) => {
        setState(State.PROCESSING);
        setMessage('Формулирую ответ');

        let status = 200;

        let question = '';
        let answer = '';

        if (typeof data === 'string') {
            const response = await fetch(`/api/question?areaId=${botId}&question=${data}`);
            status = response.status;

            if (status === 200) {
                const json = await response.json();

                question = data;
                answer = json.data;
            }
        } else {
            const formData  = new FormData();

            formData.append('botId', botId || '');
            formData.append('file', data);

            const response = await fetch('/api/question/audio', {
                method: 'POST',
                body: formData
            });
            status = response.status;

            if (status === 200) {
                const json = await response.json();

                question = json.data.question;
                answer = json.data.answer;
            }
        }

        outputText({
            sender: MessageSender.USER,
            text: question
        });

        if (status === 200) {
            if (output === Output.VOICE) {
                await outputSpeech(speechModels[0], answer);
            }

            await outputText({
                sender: MessageSender.BOT,
                text: answer
            });

            if (output !== Output.VOICE) {
                setState(State.READY);
                setMessage('');
            }
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const botId = urlParams.get('botId');

        setBotId(botId);

        if (!botId) {
            setNotification('Неверная ссылка');
            setState(State.WAITING);
            return;
        }

        TextToSpeechApi.models().then(setSpeechModels);

        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
            stream.getAudioTracks().forEach((track) => {
                track.stop();
            });

            setState(State.READY);
        }).catch(() => {
            setNotification('Необходим доступ к микрофону');
            setState(State.WAITING);
        });
    }, [setNotification]);

    return (
        <div className="app">
            <div className="container">
                <View
                    state={state}
                    output={output}
                    messages={messages}
                    speechModels={speechModels}
                />
                <Control
                    state={state}
                    setState={setState}
                    message={message}
                    setMessage={setMessage}
                    input={input}
                    setInput={setInput}
                    output={output}
                    setOutput={setOutput}
                    onQuestion={onQuestion}
                />
            </div>
        </div>
    );
}

export default App;
