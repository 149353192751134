import {createContext, ReactNode, useCallback, useContext, useEffect, useState} from "react";
import CloseIcon from "../../components/Icon/CloseIcon";
import './NotificationContext.css';

interface NotificationContextProps {
    notification: string;
    setNotification: (notification: string) => void;
}

const NotificationContext = createContext<NotificationContextProps>({
    notification: 'Test',
    setNotification: () => {}
});

interface NotificationProviderProps {
    children: ReactNode
}

let timeout: NodeJS.Timeout;

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
    const [notification, setNotification] = useState<string>('');

    const updateNotification = useCallback((notification: string) => {
        setNotification('');
        clearTimeout(timeout);

        setTimeout(() => setNotification(notification), 300);
    }, []);

    useEffect(() => {
        if (!notification) {
            return;
        }

        clearTimeout(timeout);
        timeout = setTimeout(() => setNotification(''), 5000);
    }, [notification]);

    return (
        <NotificationContext.Provider value={{ notification, setNotification: updateNotification }}>
            <div className={`notification-container ${!!notification ? 'visible' : 'hidden'}`}>
                <div className="notification">
                    <span>{notification}</span>
                    <div onClick={() => setNotification('')}>
                        <CloseIcon/>
                    </div>
                </div>
            </div>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = (): NotificationContextProps => {
    const context = useContext(NotificationContext);

    if (!context) {
        throw new Error('useNotifications must be used within a NotificationProvider');
    }

    return context;
};