import React, {useEffect, useState} from 'react';
import {TalkingHead} from "./talkinghead.mjs";
import {Message} from "../../interfaces";
import {MessageSender, Output} from "../../enums";

interface AvatarOutputProps {
    output: Output;
    messages: Message[];
}
const AvatarOutput = ({ output, messages }: AvatarOutputProps) => {
    const [avatar, setAvatar] = useState<TalkingHead>();
    const [lastSpeakingMessage, setLastSpeakingMessage] = useState<Message>();

    useEffect(() => {
        if (messages.length === 0) {
            return;
        }

        const lastMessage = messages[messages.length - 1];

        if (lastMessage.sender === MessageSender.USER || lastMessage === lastSpeakingMessage) {
            return;
        }

        if (output === Output.AVATAR) {
            avatar?.speakText(lastMessage.text);
        }

        setLastSpeakingMessage(lastMessage);
    }, [messages, lastSpeakingMessage, output, avatar]);
    useEffect(() => {
        const container = document.getElementById('avatar-output');

        if (!container) {
            return;
        }

        const head = new TalkingHead(container, {
            ttsEndpoint: "https://eu-texttospeech.googleapis.com/v1beta1/text:synthesize",
            ttsApikey: "AIzaSyB3RGbbezN8abDFyVZgqEBwAIzbhZEYyTM",
            lipsyncModules: ["en"],
            cameraView: "upper"
        });

        head.showAvatar({
            url: 'https://models.readyplayer.me/671a2eb19568b257f72fd066.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png',
            body: 'F',
            avatarMood: 'neutral',
            ttsLang: "en-GB",
            ttsVoice: "en-GB-Standard-A",
            lipsyncLang: 'en'
        }, (ev: any) => {
            console.log(ev);
        }).catch((error) => {
            console.log(error);
        });

        setAvatar(head);
    }, []);

    return (
        <div id="avatar-output" className="avatar-output" style={{ width: '100%', height: '100%' }}></div>
    );
}

export default AvatarOutput;
