import React, { useState } from 'react';
import { Output } from "../../enums";
import { useNotifications } from "../../contexts/NotificationContext/NotificationContext";
import ChatIcon from "../Icon/ChatIcon";
import VoiceIcon from "../Icon/VoiceIcon";
import AvatarIcon from "../Icon/AvatarIcon";
import CloseIcon from "../Icon/CloseIcon";
import './OutputSwitch.css';

interface OutputSwitchProps {
    value: Output;
    onChange: (value: Output) => void;
}
const OutputSwitch = ({ value, onChange }: OutputSwitchProps) => {
    const { setNotification } = useNotifications();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onSelectMenuItem = (event: any, value: Output) => {
        event.preventDefault();
        event.stopPropagation();

        onChange(value);

        setNotification(`Ассистент будет отвечать ${value === Output.TEXT ? 'текстом' : 'голосом'}`);
        setIsOpen(false);
    };

    return (
        <div className={`output-switch ${isOpen ? 'active' : ''}`} onClick={() => setIsOpen(!isOpen)}>
            {isOpen && (
                <div className="output-switch-menu">
                    <div
                        className={value === Output.TEXT ? 'active' : ''}
                        onClick={(e) => onSelectMenuItem(e, Output.TEXT)}
                    >
                        <ChatIcon />
                    </div>
                    <div
                        className={value === Output.VOICE ? 'active' : ''}
                        onClick={(e) => onSelectMenuItem(e, Output.VOICE)}
                    >
                        <VoiceIcon />
                    </div>
                    <div
                        className={value === Output.AVATAR ? 'active' : ''}
                        onClick={(e) => onSelectMenuItem(e, Output.AVATAR)}
                    >
                        <AvatarIcon />
                    </div>
                </div>
            )}
            {isOpen && (
                <CloseIcon/>
            )}
            {!isOpen && value === Output.TEXT && (
                <ChatIcon />
            )}
            {!isOpen && value === Output.VOICE && (
                <VoiceIcon />
            )}
            {!isOpen && value === Output.AVATAR && (
                <AvatarIcon />
            )}
        </div>
    );
}

export default OutputSwitch;
