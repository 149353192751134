import React from 'react';

const ChatIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.8058 4.6665H4.20579C3.69246 4.6665 3.27246 5.0865 3.27246 5.59984V17.5232C3.27246 18.0365 3.69246 18.4332 4.20579 18.4332H15.1725V22.3998C15.1725 22.7965 15.4758 23.1465 15.8258 23.2865C15.9425 23.3332 16.0591 23.3332 16.1758 23.3332C16.4558 23.3332 16.7125 23.2165 16.8991 23.0065L20.7725 18.4332H23.8058C24.3191 18.4332 24.7391 18.0365 24.7391 17.5232V5.59984C24.7391 5.0865 24.3191 4.6665 23.8058 4.6665ZM22.8725 16.5665H20.3524C20.0724 16.5665 19.7691 16.7065 19.5825 16.8932L17.0391 19.8332V17.4998C17.0391 16.9865 16.7125 16.5432 16.1991 16.5432H5.13913V6.50984H22.8725V16.5665ZM7.61246 9.79984C7.61246 9.2865 8.03246 8.8665 8.54579 8.8665H15.3125C15.8258 8.8665 16.2458 9.2865 16.2458 9.79984C16.2458 10.3132 15.8258 10.7332 15.3125 10.7332H8.54579C8.03246 10.7332 7.61246 10.3132 7.61246 9.79984ZM17.8558 10.5232C17.6925 10.3598 17.5758 10.1032 17.5758 9.86985C17.5758 9.61318 17.6691 9.37984 17.8558 9.2165C18.0425 9.02984 18.2758 8.93652 18.5091 8.93652C18.7658 8.93652 18.9991 9.02984 19.1625 9.2165C19.3258 9.37984 19.4425 9.63652 19.4425 9.86985C19.4425 10.1265 19.3491 10.3598 19.1625 10.5232C18.9758 10.7098 18.7425 10.8032 18.5091 10.8032C18.2758 10.7798 18.0425 10.6865 17.8558 10.5232Z"
                fill="#4A92FF"/>
        </svg>
    );
}

export default ChatIcon;
