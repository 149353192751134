import React, {KeyboardEvent} from 'react';
import {State} from "../../enums";
import './TextInput.css';

interface TextInputProps {
    state: State;
    text: string;
    setText: (text: string) => void;
    onSend: () => void;
}
const TextInput = ({ state, text, setText, onSend }: TextInputProps) => {
    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onSend();
        }
    };

    return (
        <div className="text-input">
            <input
                placeholder={state === State.READY ? 'Напишите свой вопрос ...' : ''}
                type="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={onKeyDown}
                disabled={state !== State.READY}
            />
        </div>
    );
}

export default TextInput;
