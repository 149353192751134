export class TextToSpeechApi {
    public static async models(): Promise<string[]> {
        return await fetch('/api/text-to-speech/models').then(response => response.json());
    }

    public static async convert(model: string, text: string): Promise<Blob> {
        return await fetch('/api/text-to-speech', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                model: model,
                text: text
            })
        }).then(response => response.blob());
    }
}