import React from 'react';
import {Subject} from "rxjs";
import {Message} from "../../interfaces";
import {MessageSender, Output, State} from "../../enums";
import TextOutput from "../../components/TextOutput/TextOutput";
import VoiceOutput from "../../components/VoiceOutput/VoiceOutput";
import AvatarOutput from "../../components/AvatarOutput/AvatarOutput";
import {TextToSpeechApi} from "../../api";
import './View.css';

interface ViewProps {
    state: State;
    output: Output;
    messages: Message[];
    speechModels: string[];
}
const View = ({ state, output, messages, speechModels }: ViewProps) => {
    const playMessages = (messages: Message[]): Subject<Message> => {
        const subject = new Subject<Message>();
        const audio = document.createElement('audio');

        let prevBlob: Blob | undefined = undefined;
        let nextBlob: Blob | undefined = undefined;

        const subjectInterval = setInterval(() => {
            if (subject.observed) {
                return;
            }

            audio.pause();
        }, 100);
        const processMessage = async (index: number, resolve: () => void) => {
            if (index !== 0 && !subject.observed) {
                return;
            }

            subject.next(messages[index]);

            if (!nextBlob) {
                prevBlob = await TextToSpeechApi.convert(
                    messages[index].sender === MessageSender.BOT ? speechModels[0] : speechModels[1],
                    messages[index].text
                );
            } else {
                prevBlob = nextBlob;
            }

            audio.src = window.URL.createObjectURL(prevBlob);
            audio.onended = () => {
                if (messages.length > index + 1 && prevBlob === nextBlob) {
                    return;
                }

                resolve();
            };

            audio.play().then(async () => {
                if (messages.length <= index + 1) {
                    return;
                }

                const nextMessage = messages[index + 1];
                nextBlob = await TextToSpeechApi.convert(
                    nextMessage.sender === MessageSender.BOT ? speechModels[0] : speechModels[1],
                    nextMessage.text
                );

                if (audio.ended || audio.paused) {
                    resolve();
                }
            });
        };

        new Promise<void>(async (resolve) => {
            for (let i = 0; i < messages.length; i++) {
                await new Promise<void>((resolve) => {
                    processMessage(i, resolve);
                });
            }

            resolve();
        }).then(() => {
            subject.complete();
            clearInterval(subjectInterval);
        });

        return subject;
    };

    return (
        <div className="view">
            {output === Output.TEXT && (
                <TextOutput messages={messages} playMessages={playMessages} />
            )}
            {output === Output.VOICE && (
                <VoiceOutput state={state} />
            )}
            <div style={{ width: '100%', height: '100%', display: output === Output.AVATAR ? "block" : "none" }}>
                <AvatarOutput output={output} messages={messages} />
            </div>
        </div>
    );
}

export default View;
