import React from 'react';
import { Input } from "../../enums";
import { usePlay } from "../../contexts/PlayContext/PlayContext";
import PlayIcon from "../Icon/PlayIcon";
import ArrowIcon from "../Icon/ArrowIcon";
import KeyboardIcon from "../Icon/KeyboardIcon";
import MicrophoneIcon from "../Icon/MicrophoneIcon";
import './InputSwitch.css';

interface InputSwitchProps {
    value: Input;
    onChange: (value: Input) => void;
    canSend: boolean;
    onSend: () => void;
    onPlay: () => void;
}
const InputSwitch = ({ value, onChange, canSend, onSend, onPlay }: InputSwitchProps) => {
    const { canPlay } = usePlay();

    const onClick = () => {
        if (value === Input.TEXT && canSend) {
            onSend();
        } else {
            onChange(value === Input.TEXT ? Input.VOICE : Input.TEXT);
        }
    };

    return (
        <div className="input-switch" onClick={canPlay && value === Input.TEXT ? onPlay : onClick}>
            {canPlay && value === Input.TEXT && (
                <PlayIcon />
            )}
            {!canPlay && value === Input.TEXT && canSend && (
                <ArrowIcon />
            )}
            {!canPlay && value === Input.TEXT && !canSend && (
                <MicrophoneIcon />
            )}
            {value === Input.VOICE && (
                <KeyboardIcon />
            )}
        </div>
    );
}

export default InputSwitch;
