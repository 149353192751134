import React from "react";
import { Subject } from "rxjs";
import { MessageSender } from "../../enums";
import { Message } from "../../interfaces";
import PlayIcon from "../Icon/PlayIcon";
import PauseIcon from "../Icon/PauseIcon";
import "./TextOutput.css";

interface TextOutputProps {
    messages: Message[];
    playMessages: (messages: Message[]) => Subject<Message>;
}
const TextOutput = ({ messages, playMessages }: TextOutputProps) => {
    const [subject, setSubject] = React.useState<Subject<Message>>();
    const [playingMessage, setPlayingMessage] = React.useState<Message>();

    const play = (message: Message) => {
        if (playingMessage) {
            stop();
        }

        setPlayingMessage(message);
        const subject = playMessages(messages.slice(messages.indexOf(message), messages.length));

        subject.subscribe({
            next: (message: Message) => {
                setPlayingMessage(message);
            },
            complete: () => {
                setPlayingMessage(undefined);
            }
        });

        setSubject(subject);
    };

    const stop = () => {
        subject?.unsubscribe();
        setPlayingMessage(undefined);
    };

    const playingMessageIndex = playingMessage ? messages.indexOf(playingMessage) : -1;

    return (
        <div id="text-output" className="text-output">
            {messages.map((p, index) => (
                <div key={index} className={`text ${MessageSender[p.sender].toLowerCase()}`}>
                    {p.text}
                    {p.sender === MessageSender.BOT && (
                        <div onClick={() => playingMessageIndex !== index ? play(p) : stop()}>
                            {playingMessageIndex !== index && (
                                <PlayIcon/>
                            )}
                            {playingMessageIndex === index && (
                                <PauseIcon/>
                            )}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default TextOutput;
